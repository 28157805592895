/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${(props) =>
    props.isCustom
      ? props.theme.color.face.contrast
      : props.theme.color.face.main};
  display: block;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.text.contrast};
    padding: 15px 20px;
    width: 100%;
    max-width: 263px;
    height: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: normal;
    font-weight: 700;
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className} isCustom={isCustom}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell
        to={to}
        isAnchor={isAnchor}
        className="d-flex align-items-center justify-content-between"
      >
        <span>{children}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3L12 9L2 15V3Z"
            stroke="#1D2217"
            strokeWidth="3"
          />
        </svg>
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault
