/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { FaFacebookF, FaMobileAlt } from 'react-icons/fa'
// Images
import imgLogo from 'img/logo.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import SocialIcon from 'components/elements/SocialIcon'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.face.main};
  position: relative;
  padding: 0px 0;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 148px;
  padding-top: 48px;

  transition: all 300ms ease;
  position: relative;
  @media screen and (max-width: 1060px) {
    width: 768px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 48px 16px 0 16px;
    height: auto;
    display: block;
  }
`

const Brand = styled(CustomLink)`
  & > img {
    height: 100px;
    width: 325px;
    object-fit: contain;
    object-position: center;
  }
  @media screen and (max-width: 1060px) {
    & > img {
      height: 75px;
      width: 195px;
    }
  }
  transition: all 300ms ease;
  display: block;
  /* margin-bottom: auto; */
  margin-top: 4px;

  &:hover {
    opacity: 0.7;
  }
`

const NavButton = styled.button`
  border: 1px solid ${(props) => props.theme.color.text.secondary};
  position: absolute;
  top: 65px;
  right: 16px;
  width: 44px;
  height: 44px;
  padding: 0 10px;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  height: 2px;
  width: 100%;
  margin: 5px 0;
`
const Top = styled.div`
  display: flex;
  background: ${(props) => props.theme.color.face.secondary};
  width: 100%;
  height: 48px;
  padding: 0px 0;
  justify-content: flex-end;
  padding-right: calc(50% - 530px);
  z-index: 2;
  position: absolute;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    padding-right: calc(50% - 380px);
  }
  @media screen and (max-width: 768px) {
    padding-right: 30px;
  }
`
const PhoneWrapper = styled.a`
  width: 140px;
  height: 29px;
  line-height: 29px;
  border: 1px solid white;
  color: ${(props) => props.theme.color.text.secondary};
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  padding: 0 8px;
  display: flex;

  & > svg {
    margin-top: 4px;
    margin-right: 10px;
  }

  &:hover {
    color: #cab996;
    text-decoration: underline;
  }
`
const Header = ({ active }) => {
  const { wordpressWpComponenten: edges } = useStaticQuery(graphql`
    {
      wordpressWpComponenten(wordpress_id: { eq: 137 }) {
        acf {
          menu {
            button {
              title
              url
            }
          }
          phonenumber {
            title
            url
          }
          socials {
            faceboo
            linkedin
          }
        }
      }
    }
  `)

  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <StyledHeader>
      <Top>
        <PhoneWrapper href={edges.acf.phonenumber.url}>
          <FaMobileAlt size={18} color="white" />
          <span>{edges.acf.phonenumber.title}</span>
        </PhoneWrapper>
        <SocialIcon href={edges.acf.socials.faceboo} target="_blank">
          <FaFacebookF />
        </SocialIcon>
      </Top>
      <Container className="container">
        <Brand to="/">
          <img src={imgLogo} alt="" />
        </Brand>

        <HeaderMenuDesktop items={edges.acf.menu} active={active} />

        <NavButton type="button" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? (
            <>
              <NavButtonStripe />
              <NavButtonStripe />
              <NavButtonStripe />
            </>
          ) : (
            <NavButtonClose />
          )}
        </NavButton>

        <HeaderMenuMobile
          items={edges.acf.menu}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </Container>
    </StyledHeader>
  )
}

const StyledHeaderMenuMobile = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: absolute;
  margin-left: -16px;
  margin-top: 0;
  width: 100%;
  max-height: ${(props) => (props.isCollapsed ? '0' : '360px')};
  z-index: 99;
  transition: all 300ms ease;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    ul {
      padding-left: 0;
    }
  }
`

const NavButtonClose = styled.div`
  position: relative;
  width: 21px;
  height: 32px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.secondary};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
    transition: all 300ms ease;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const HeaderMenuMobile = ({ items, isCollapsed }) => (
  <StyledHeaderMenuMobile isCollapsed={isCollapsed}>
    <ul>
      <HeaderMenuItems items={items} />
    </ul>
  </StyledHeaderMenuMobile>
)

const StyledHeaderMenuDesktop = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 0;
  height: 100%;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const HeaderMenuDesktop = ({ items, active }) => {
  return (
    <StyledHeaderMenuDesktop>
      <HeaderMenuItems items={items} active={active} />
    </StyledHeaderMenuDesktop>
  )
}

const Item = styled.li`
  &:before {
    content: ' ';
    width: 25px;
    height: 25px;
    transform: translate(0, -20px) scale(1.5, 1) rotate(-45deg);
    background: ${(props) => (props.active ? '#1D2217' : 'transparent')};
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
      width: 0px;
      height: 0px;
    }
  }
  font-weight: 400;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  margin: 0 26px;
  @media screen and (max-width: 1060px) {
    margin: 0 12px;
  }
  @media screen and (max-width: 768px) {
    margin: 12px auto 0px auto;
    text-align: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 8px;
    width: 200px;
    &:last-child {
      border-bottom: none;
    }
  }
  transition: all 300ms ease;
  & > a {
    color: ${(props) => props.theme.color.text.contrast};
    font-size: 14px;
    margin-top: 10px;
    text-transform: uppercase;
    &[aria-current] {
    }

    display: inline;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      right: 0;
      width: 0;
      bottom: -5px;
      background: #000;
      height: 4px;
      transition-property: width;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
    }
    &:hover {
      left: 0;
      right: auto;
      width: 100%;
      text-decoration: underline;
    }
  }
`

const HeaderMenuItems = ({ items, active }) => {
  return (
    <>
      {items.map(({ button: { url, title } }, index) => (
        <Item key={index} active={title === active ? 1 : 0}>
          <CustomLink to={url}>{title}</CustomLink>
        </Item>
      ))}
    </>
  )
}

export default Header
