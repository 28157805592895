import React from 'react'
import styled from 'styled-components'
import ImageBackground from 'components/elements/ImageBackground'
import parse from 'html-react-parser'

const Container = styled(ImageBackground)`
  height: 440px;
  width: 100%;
  position: absolute !important;
`

const HeroWrapper = styled.div`
  height: 100%;
  min-height: 440px;
  display: flex;
  align-items: center;
`

const TitleWrapper = styled.div`
  width: 400px;
  height: 360px;
  border: 8px solid ${(props) => props.theme.color.face.main};
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  margin-right: auto;
  display: flex;
  text-align: left;
  transition: all 300ms ease;
  padding: 35px 40px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 0.4192051820728291) 70%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: 2000px) {
    width: unset;
    height: unset;
    min-width: 500px;
    min-height: 360px;
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    @media (min-width: 992px) {
      font-size: 29px;
      line-height: normal;
      font-weight: 400;
    }
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: normal;
      font-weight: 400;
    }
  }

  & p {
    color: white;
  }

  & a {
    color: #cab996;
    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
    height: 100%;
    border: 6px solid white;
    font-size: 24px;
    line-height: 28px;
    padding: 28px 24px;
    font-size: 20px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    width: 270px;
    height: 240px;
    margin: auto auto;
    font-size: 16px;
    line-height: 22px;
  }
`

const Hero2 = ({ backgroundImg, title, aboveFold }) => {
  return (
    <HeroWrapper className="position-relative">
      <Container src={backgroundImg} aboveFold={aboveFold} />
      <div className="container position-relative">
        <TitleWrapper>{parse(title)}</TitleWrapper>
      </div>
    </HeroWrapper>
  )
}
export default Hero2
