import React from 'react'
import styled from 'styled-components'
import ServiceItem from 'components/elements/ServiceItem'
import { useStaticQuery, graphql } from 'gatsby'

const Container = styled.div`
  margin-top: 50px;

  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  & > a {
    margin-bottom: 2rem !important;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    & > div {
      margin: 20px auto;
    }

    & > a {
      margin-bottom: 2rem !important;
    }
  }
`
export default function Services({ ids }) {
  const { allWordpressWpServices: services } = useStaticQuery(graphql`
    {
      allWordpressWpServices {
        edges {
          node {
            path
            wordpress_id
            title
            acf {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                  publicURL
                }
              }
              button {
                url
              }
              type
            }
          }
        }
      }
    }
  `)

  const items = services.edges.filter(
    ({ node: { wordpress_id: wpId } }) => ids.indexOf(wpId) !== -1
  )

  return (
    <div className="container">
      <Container className="row flex-column flex-wrap flex-md-row align-items-center">
        {items.map(({ node }) => {
          return (
            <ServiceItem
              key={node.wordpress_id}
              title={node.title}
              image={node.acf.image}
              url={node.acf.button.url}
              uri="/diensten"
            />
          )
        })}
      </Container>
    </div>
  )
}
