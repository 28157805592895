import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const mainTheme = {
  color: {
    face: {
      main: '#F1EBDA',
      contrast: '#3F4321',
      secondary: '#1D2217',
      light: 'white',
    },

    text: {
      main: '#1D2217',
      contrast: '#3F4321',
      secondary: '#F1EBDA',
      light: 'white',
    },
  },

  font: {
    family: {
      main: 'Gill Sans, sans-serif',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '20px',
      l: '24px',
      xl: '32px',
      xxl: '45px',
      xxxl: '70px',
    },

    weight: {
      xs: 200,
      s: 300,
      m: 400,
      l: 600,
      xl: 700,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) =>
    Object.keys(props.theme.color.face).map((key) => {
      return `.color-background-${key} { background-color: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.color.text).map((key) => {
      return `.color-${key} { color: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.family).map((key) => {
      return `.font-family-${key} { font-family: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.size).map((key) => {
      return `.font-size-${key} { font-size: ${props.theme.color.face[key]}; }`
    })}

  ${(props) =>
    Object.keys(props.theme.font.weight).map((key) => {
      return `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    })}
`

const Theme = ({ children }) => (
  <ThemeProvider theme={mainTheme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
)

export default Theme
