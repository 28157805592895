/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ButtonDefault from '../elements/ButtonDefault'

const StyledFooter = styled.section`
  background: ${(props) => props.theme.color.face.secondary};
  padding: 36px 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-size: 16px;
  line-height: 19px;
  padding: 0 8px 18px 8px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 3px solid #cab996;
  margin-bottom: 54px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 16px;
    padding: 0 6px 16px 6px;
    border-bottom: 2px solid #cab996;
    margin-bottom: 40px;
  }
`

const MenuItem = styled(Link)`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  line-height: 35px;
  display: block;
  width: fit-content;
  margin: 0 auto;
  &:hover {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 28px;
  }
`
const Column2 = styled.div`
  border: 2px solid white;
  border-radius: 3px;

  padding: 66px 28px 100px 28px;
  @media screen and (max-width: 991px) {
    padding: 46px 18px 60px 18px;
    margin: 2rem 1rem;
  }
`
const Address = styled.div`
  font-size: 16px;
  line-height: normal;
  color: white;
  text-align: center;

  & a {
    color: #fff;
    margin-bottom: 1rem;
    display: block;
  }

  & p {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: normal;
  }
`

const Col = styled.div`
  @media (min-width: 992px) {
    padding-top: 67px;
  }
`

const BottomFooter = styled.div`
  height: 20px;
  background-color: ${(props) => props.theme.color.face.main};
`

const Footer = () => {
  const { wordpressWpComponenten: edges } = useStaticQuery(graphql`
    {
      wordpressWpComponenten(wordpress_id: { eq: 138 }) {
        acf {
          menu_1 {
            title
            menu {
              button {
                title
                url
              }
            }
          }
          menu_2 {
            title
            menu_1 {
              title
              menu {
                button {
                  title
                  url
                }
              }
            }
            menu_2 {
              title
              menu {
                button {
                  title
                  url
                }
              }
            }
          }
          menu_3 {
            title
            street
            zip_city
            email
            phone {
              title
              url
            }
            button {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <StyledFooter>
        <div className="container">
          <div className="row">
            <Col className="col-lg-2">
              <Title>{edges.acf.menu_1.title}</Title>
              {edges.acf.menu_1.menu.map(({ button }) => {
                return (
                  <MenuItem key={button.url} to={button.url}>
                    {button.title}
                  </MenuItem>
                )
              })}
            </Col>
            <div className="col-md-1" />
            <Column2 className="col-lg-5">
              <Title>{edges.acf.menu_2.title}</Title>
              <div className="d-flex text-left justify-content-between">
                <Services
                  title={edges.acf.menu_2.menu_1.title}
                  items={edges.acf.menu_2.menu_1.menu}
                />
                <Services
                  title={edges.acf.menu_2.menu_2.title}
                  items={edges.acf.menu_2.menu_2.menu}
                />
              </div>
            </Column2>
            <div className="col-md-1" />
            <Col className="col-lg-3">
              <Title>{edges.acf.menu_3.title}</Title>
              <Address>
                <p>{edges.acf.menu_3.street}</p>
                <p>{edges.acf.menu_3.zip_city}</p>
                <Link to={edges.acf.menu_3.phone.url}>
                  {edges.acf.menu_3.phone.title}
                </Link>
                <Link to={`mailto:${edges.acf.menu_3.email}`}>
                  {edges.acf.menu_3.email}
                </Link>
              </Address>
              <ButtonDefault to={edges.acf.menu_3.button.url}>
                {edges.acf.menu_3.button.title}
              </ButtonDefault>
            </Col>
          </div>
        </div>
      </StyledFooter>
      <BottomFooter />
    </>
  )
}

export default Footer

const ServiceItem = styled(Link)`
  font-size: 14px;
  line-height: 35px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  &:first-child:before {
    width: 0;
    height: 0;
    display: block;
  }
  &:first-child {
    display: block;
  }
  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 28px;
  }
`
const Services = ({ title, items }) => {
  return (
    <div className="text-center d-flex flex-column">
      <span className="mb-2 color-light font-weight-xl">{title}</span>
      {items.map(({ button }) => {
        return (
          <ServiceItem key={button.url} to={button.url}>
            <span>{button.title}</span>
          </ServiceItem>
        )
      })}
    </div>
  )
}
