import React from 'react'

import styled from 'styled-components'

const Heading = styled.div`
  display: flex;
  margin: 10px 0 10px 0;
  font-size: 16px;
  img {
    width: 20px;
    height: 22px;
    object-fit: contain;
    object-position: -4px 50%;
    margin-right: 20px;
  }
  @media screen and (max-width: 1060px) {
    margin: 7px 0 7px 0;
    font-size: 12px;
    img {
      width: 16px;
      height: 19px;
      object-fit: contain;
      object-position: -4px 50%;
      margin-right: 10px;
    }
  }
`

export default function Label({ text }) {
  return (
    <Heading>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="18"
        viewBox="0 0 15 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3L12 9L2 15V3Z"
          stroke="#1D2217"
          strokeWidth="3"
        />
      </svg>
      <span className="ml-2">{text}</span>
    </Heading>
  )
}
