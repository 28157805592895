import React from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

const Container = styled.div`
  height: 60px;
  background: ${(props) =>
    props.type === 0
      ? props.theme.color.face.contrast
      : props.theme.color.face.main};

  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  line-height: normal;
  text-align: left;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 1;
  border: 1px solid ${(props) => props.theme.color.face.contrast};
  transition: all 300ms ease;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  @media screen and (max-width: 1060px) {
    height: 44px;
    padding: 0 18px;
    line-height: 15px;
    font-size: 12px;
  }

  border-radius: 4px;
`
const Text = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 40px;
  @media screen and (max-width: 1060px) {
    margin-right: 30px;
  }
  color: ${(props) =>
    props.type === 0
      ? props.theme.color.text.light
      : props.theme.color.face.contrast};
`

const Shadow = styled.div`
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
  height: 60px;
  border: ${(props) => `5px solid ${props.color}`};
  z-index: -1;
  @media screen and (max-width: 1060px) {
    margin-top: 6px;
    margin-left: 6px;
    width: 100%;
    height: 44px;
    border: ${(props) => `3px solid ${props.color}`};
  }
`
export default function Button({ label, shadowColor, to, type = 0 }) {
  return (
    <Link to={to} className="position-relative">
      {!!shadowColor && <Shadow color={shadowColor} />}
      <Container type={type}>
        <Text type={type}>{parse(label)}</Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="18"
          viewBox="0 0 15 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3L12 9L2 15V3Z"
            stroke="#F1EBDA"
            strokeWidth="3"
          />
        </svg>
      </Container>
    </Link>
  )
}
