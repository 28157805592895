import React from 'react'
import styled from 'styled-components'

import parse from 'html-react-parser'

const Container = styled.div`
  margin-bottom: 32px;
`
const Text = styled.h2`
  color: black;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  transition: all 300ms ease;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 24px;
`
const Border = styled.div`
  height: 3px;
  width: 200px;
  background: ${(props) => props.theme.color.face.main};
  margin-left: auto;
  transition: all 300ms ease;
  margin-right: auto;
  @media screen and (max-width: 1060px) {
    height: 2px;
    width: 150px;
  }
`
export default function Heading({ text }) {
  return (
    <Container>
      <Text>{parse(text)}</Text>
      <Border />
    </Container>
  )
}
