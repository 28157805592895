import React from 'react'
import styled from 'styled-components'
import TriangleImg from 'img/triangle.png'

import ImageBackground from 'components/elements/ImageBackground'
import { Link } from 'gatsby'

const Container = styled(Link)`
  width: 320px;
  height: 320px;
  /* @media screen and (max-width: 1060px) {
    width: 272px;
    height: 272px;
  } */

  & .project-title {
    line-height: 22px;

    @media (max-width: 1399px) {
      font-size: 22px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
    }

    @media (max-width: 575px) {
      font-size: 14px;
    }
  }

  &:hover {
    opacity: 0.75;
  }
`
const Shadow = styled.div`
  background: transparent;
  border: 8px solid ${(props) => props.theme.color.face.main};
  border-radius: 3px;
  width: 300px;
  height: 300px;
  position: relative;
  transition: all 300ms ease;
  @media screen and (max-width: 1060px) {
    /* width: 200px;
    height: 200px; */
    border: 6px solid ${(props) => props.theme.color.face.main};
  }
`
const ImgContainer = styled(ImageBackground)`
  margin-top: 20px;
  margin-left: 20px;
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  transition: all 300ms ease;

  @media screen and (max-width: 1060px) {
    margin-top: 12px;
    margin-left: 12px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
  }
`
const Overlay = styled.div`
  padding: 25px 20px;
  background: linear-gradient(
    black 0%,
    black 20%,
    transparent 40%,
    transparent 0%
  );
  color: white;
  font-size: 25px;
  transition: all 300ms ease;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  & > img {
    object-fit: contain;
    object-position: 0 7px;
  }

  & > div {
    height: 10%;
  }

  @media screen and (max-width: 1060px) {
    padding: 16px 14px;
    font-size: 17px;
    & > img {
      object-position: 0 2px;
      width: 20px;
    }
  }
`

export default function ProjectItem({ image, title, uri }) {
  return (
    <Container to={uri}>
      <Shadow>
        <ImgContainer src={image}>
          <Overlay>
            <div className="d-flex align-items-center justify-content-between w-100">
              <span className="project-title">{title}</span>
              <img src={TriangleImg} alt="" />
            </div>
          </Overlay>
        </ImgContainer>
      </Shadow>
    </Container>
  )
}
