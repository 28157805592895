import React from 'react'
import styled from 'styled-components'
import ServiceItem from 'components/elements/ServiceItem'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ProjectItem from '../elements/ProjectItem'

const Container = styled.div`
  margin-top: 50px;
  min-width: 1024px;
  width: 1060px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  & a:hover {
    opacity: 0.85;
  }

  @media screen and (max-width: 1439px) {
    justify-content: space-around;
    & > a > a > div {
      /* max-width: 220px !important;
      max-height: 220px !important; */
    }
  }

  @media screen and (max-width: 1060px) {
    min-width: 668px;
    width: 668px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    & > div {
      margin: 20px auto;
    }
  }
`

export default function Posts() {
  const { allWordpressPost: posts } = useStaticQuery(graphql`
    {
      allWordpressPost {
        edges {
          node {
            wordpress_id
            title
            acf {
              thumbnail {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  const items = posts.edges

  return (
    <Container>
      {items.map(({ node }) => {
        return (
          <Link to={node.path} style={{ marginBottom: '4rem' }}>
            <ProjectItem
              key={node.wordpress_id}
              image={node.acf.thumbnail}
              title={node.title}
            />
          </Link>
        )
      })}
    </Container>
  )
}
